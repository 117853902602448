export const pageTitle = 'Ścianka banerowa jednostronna';
export const pageDescription = 'Ścianka banerowa to jeden z oferowanych przez nas systemów wystawienniczych. Wytrzymała grafika nadrukowana jednostronnie z trwałą, regulowaną konstrukcją to gwarancja dobrej reklamy.';
export const mainHeader = 'Ścianka banerowa';

export const wallInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**${mainHeader} <span class="orange">czas realizacji</span>**
      \n ok. 3 dni roboczych`,
    },
    {
      article: `**${mainHeader} <span class="orange">koszt wysyłki</span>**
      \n 25,00 zł/netto`,
    },
    {
      article: `\n **${mainHeader} <span class="orange">wymiar</span>**
      \n Dostępne wymiary ścianki tekstylnej prostej (wys. x szer.):
      \n * ścianka banerowa 2,4 m,  \n możliwa regulacja:  \n 100-251cm (wys.) x 143-254cm (szer.),  \n obszar widocznej grafiki:  \n 86-243cm (wys.) x 133-241cm (szer.)
      \n * ścianka tekstylna 2,4 m,  \n możliwa regulacja:  \n 100-251 cm (wys.) x 143-254cm (szer.),  \n obszar widocznej grafiki:  \n taki sam jak konstrukcji
      \n * ścianka tekstylna 3 m,  \n możliwa regulacja:  \n 183-261cm (wys.) x 183-320cm (szer.),  \n obszar widocznej grafiki:  \n taki sam jak konstrukcji
      `,
    },
  ],
  rightColumn: [
    {
      article: `**${mainHeader} <span class="orange">opis</span>**
      \n Ekonomiczna ścianka banerowa wykonana z konstrukcji ze srebrnego anodowanego aluminium składającej się z regulowanych cylindrycznych profili które w łatwy i szybki sposób (montaż ścianki trwa kilkanaście minut i z narzędzi wymaga wyłącznie użycia śrubokręta do przykręcenia stóp) połączysz poprzez śruby motylkowe. 
      System utrzymuje się na dwóch stabilnych stopach przykręcanych do podstawy ramy. Możliwość regulacji wysokości i szerokości pozwala na dopasowanie systemu do każdego pomieszczenia. Grafika nadrukowana jednostronnie na wytrzymałym materiale banerowym mocowanym dzięki tunelom lub na trwałej poliestrowej tkaninie naciąganej na konstrukcje. 
      Ścianka jest wielokrotnego użytku z możliwość dodrukowania nowej grafiki w zestawie z materiałową torbą transportową.
      \n Materiał grafiki: baner standard 510g. Druk wykonywany ekonomicznym i trwałym drukiem solwentowym.
      \n Materiał grafiki: tekstylna Display Stretch 260 g/m2 Druk wykonywany bezwonny i ekologicznym drukiem sublimacyjnym.
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">zalety i cechy</span>**
      \n * ekonomiczna,
      \n * szybki i prosty montaż w kilkanaście minut,
      \n * trwała konstrukcja,
      \n * możliwość regulacji wysokości i szerokości
      \n * możliwość wymienienia grafiki,
      \n * wygodna w transporcie,
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">zastosowanie</span>**
      \n * na targach,
      \n * na eventach okolicznościowy,
      \n * w galeriach i sklepach,
      \n * w biurze,
      \n * w studiach telewizyjnych,
      \n * nagrań filmów na Youtube i zdjęć na Instagram,
      \n * jako tło do zdjęć.
      `,
    },
    {
      article: `**${mainHeader} <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
