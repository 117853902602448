// REPEATED WITH EVERY WALLS PRODUCT

export const preparingFile = `
  **Jak poprawnie przygotować plik do druku ścianki?**
  \n * Plik przygotowany w oparciu na konkretnym szablonie ścianki,
  \n * Plik przygotowany w palecie kolorów CMYK,
  \n * Wszystkie fonty zamienione na krzywe,
  \n * Rozdzielczość wykorzystanych bitmap (np. zdjęć) w projekcie powinny mieć min. 150 dpi (należy 
  pamiętać aby przy skalowaniu projektu rozdzielczość bitmapy była proporcjonalnie większa np. jeśli 
  przesłany projekt jest w skali 1:2, wtedy rozdzielczość bitmapy powinna wynosić 300 dpi),  
  \n * Plik zapisany w formacie PDF.
  \n Aby uzyskać głęboką czerń sugerujemy ustawienie składowych CMYK: 100-40 -40-40.
`;

export const priceListHint = 'Cena dotyczy całego zestawu w skład którego wchodzi konstrukcja, wydruk oraz torba transportowa. Podane ceny są cenami netto. Cena nie zawiera kosztów wysyłki.';
